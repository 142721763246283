<template>
  <div class="rank_box">
    <div class="rank_tips">
      在
      <spna v-if="searchInfo.periodType==1">【所有的时间里】</spna>
      <span  v-if="searchInfo.periodType==4">【{{sumInfoBox.period}}】</span>
      <span  v-if="searchInfo.periodType==2">【本周排名】</span>
      <span  v-if="searchInfo.periodType==3">【上周排名】</span>
      ，{{sumInfoBox.name}}
      共获得总分打卡得分<span>{{sumInfoBox
      .score}}</span>分，在所处的赛区中排名第
      <span>{{sumInfoBox.ranking}}</span>名，赛区排名信息请看下图：
    </div>
    <div class="info_box"><span>【{{sumInfoBox.area}}】{{sumInfoBox.name}}</span><span>小组排名：{{sumInfoBox.ranking}}</span><span>小组得分：{{sumInfoBox.score}}</span></div>
    <div class="rank_info_box">
      <div class="group_rank" v-if="groupList!=null">
        <el-divider content-position="left">小组排名</el-divider>
        <div v-for="item in groupList" class="rank_list_box">
          <div class="group_name"><p><span>{{item.ranking}}</span>{{item.groupName}}</p><span>{{item.punchAndAward}}</span></div>
          <el-progress :show-text="false" :stroke-width="18" :percentage="item.percentage" :color="customColors"></el-progress>
        </div>

      </div>
      <div class="group_person_rank" v-if="personalList!=null">
        <el-divider content-position="left">在此期间，每位队员排名</el-divider>
        <div v-for="item in personalList" class="rank_list_box">
          <div class="group_name"><p><span>{{item.ranking}}</span>{{item.trueName}}</p><span>{{item.punchAndAward}}</span></div>
          <el-progress :show-text="false" :stroke-width="18" :percentage="item.percentage" :color="customColors"></el-progress>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import api from '@/api/index'
    export default {
        name: "current-sum",
        props:{
          groupId:{
            type:Number,
            required:true
          },
          searchInfo:{
            type:Object,
            required:true
          }
        },
        data() {
            return {
              sumInfoBox:{},
              groupList:null,
              personalList:null,
              customColors: [
                {color: '#5cb8ff', percentage: 20},
                {color: '#e6e166', percentage: 40},
                {color: '#ff9710', percentage: 60},
                {color: '#fa663f', percentage: 80},
                {color: '#ff2c27', percentage: 100}
              ]
            }
        },
      created(){
        let dataArr={}
        if(this.searchInfo.periodType!=4){
          dataArr={
            periodType:this.searchInfo.periodType,
            groupId: this.groupId,
            tastTypes:this.searchInfo.tastTypes,
          }
        }else {
          dataArr={
            periodType:this.searchInfo.periodType,
            groupId: this.groupId,
            tastTypes:this.searchInfo.tastTypes,
            startTime: this.searchInfo.startTime,
            endTime: this.searchInfo.endTime,
          }
        }
        this.getGroupSum(dataArr)
      },
      methods:{
          //查询小组配置中的本期总结
        async getGroupSum(dataArr){
          console.log('总结',dataArr)
          try {
            let res= await api.groupSumGet(dataArr)
            if(res.code==200){
              this.sumInfoBox=res.data
              let groupMax =null
              let personMax=null
              if(res.data.groupList!=null && res.data.groupList.length>0){
                res.data.groupList.forEach(item=>{
                  if(item.ranking==1){
                    groupMax=item.punchAndAward
                  }
                  item.percentage=(item.punchAndAward/groupMax)*100
                })
                this.groupList=res.data.groupList
              }
              if(res.data.personalList!=null && res.data.personalList.length>0){
                res.data.personalList.forEach(item=>{
                  if(item.ranking==1){
                    personMax=item.punchAndAward
                  }
                  item.percentage=(item.punchAndAward/personMax)*100
                })
                this.personalList=res.data.personalList
              }

            }
          }catch (e) {
            this.$message.error(e.msg)
          }
        }
      }
    }
</script>

<style scoped lang="less">
.rank_box{
  height:600px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 24px;
  line-height:30px;
  .rank_tips{
    font-size: 18px;
    span{
      color: #0bf3c2;
      font-size: 30px;
    }
  }
  .info_box{
    display: flex;
    font-size: 20px;
    height: 60px;
    align-items: center;
    margin: 10px 50px;
    background-color: rgba(10, 236, 0,0.2);
    border-radius: 5px;
    padding: 0 10px;
    span{
      flex: 3;
    }
    span:nth-child(2){
      text-align: center;
      flex: 2;
    }
    span:last-child{
      text-align: center;
      flex: 2;
    }
  }
  .rank_info_box{
    display: flex;
    .group_rank,.group_person_rank{
      flex: 1;
      margin: 0 5px;
      padding: 10px;
      border: 1px solid #eefafa;
      border-radius: 4px;
    }
  }
  .rank_list_box{
    margin-bottom: 20px;
    .group_name{
      display: flex;
      padding: 0px 5px;
     span{
       flex: 1;
       text-align: right;
     }
      p {
        flex: 5;
        span{
          font-size:30px;
          font-family: YouSheBiaoTiHei;
          margin-right: 10px;
          color: #0bf3c2;
        }

      }
    }
  }
}
</style>
