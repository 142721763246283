<template>
  <div class="main_content" :class="isSatis ? 'statis' : ''">
    <div class="title_box">
      <span></span>
      <h5 v-if="isSatis">小组列表</h5>
      <h5 v-else>小组排名</h5>
    </div>
    <div class="line"></div>
    <div class="operate_box">
      <div class="search_box" v-if="isSatis" style="height: 52px">
        <div class="input_box">
          <span>小组名称：</span>
          <el-input placeholder="请输入小组名称" v-model="searchForm.groupName" style="width: 200px"></el-input>
        </div>
        <div class="input_box"><el-button icon="el-icon-search" class="search_btn" @click="searchBtn"></el-button></div>
      </div>
      <div class="search_box" v-else>
        <div class="input_box">
          <span>时间段：</span>
          <el-select v-model="searchForm.periodType" clearable placeholder="请选择" class="device_box">
            <el-option v-for="item in timeSlot" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="input_box">
          <span>起止时间：</span>
          <el-date-picker class="picker_box" v-model="startTime" type="date"
            :disabled="searchForm.periodType == 4 ? false : true" placeholder="选择日期"
            :picker-options="beginPickerOptions">
          </el-date-picker>
          -
          <el-date-picker class="picker_box" v-model="endTime" type="date"
            :disabled="searchForm.periodType == 4 ? false : true" placeholder="选择日期" :picker-options="endPickerOptions">
          </el-date-picker>
        </div>
        <div class="input_box">
          <span>所在赛区：</span>
          <el-input placeholder="请输入所在赛区" v-model="searchForm.area" class="set_input_style"></el-input>
        </div>
        <div class="input_box" v-if="this.$store.state.role === 'admin'">
          <span>地区：</span>
          <!--省市三级联动-->
          <!--<el-cascader-->
          <!--placeholder="请选择"-->
          <!--v-model="searchForm.code"-->
          <!--:options="araeList"-->
          <!--:clearable="true"-->
          <!--ref="myCascader"-->
          <!--@change="getArea"-->
          <!--&gt;-->
          <!--</el-cascader>-->
          <el-select v-model="searchForm.code" @focus="getArea" filterable @change="getAreaCode" clearable>
            <el-option v-for="item in araeList" :key="item.regionCode" :value="item.regionCode" :label="item.area" />
          </el-select>
        </div>
        <div class="input_box" v-if="this.$store.state.role === 'admin'">
          <span>社区：</span>
          <el-select v-model="searchForm.communityId" filterable clearable placeholder="请选择">
            <el-option v-for="item in communityList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <!--<div class="input_box">
          <span>打卡类型：</span>
          <el-select v-model="searchForm.tastTypes"
                     clearable
                     placeholder="请选择"
                     multiple
                     style="width: 300px">
            <el-option
              v-for="item in taskTlist"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>-->
        <div class="input_box"><el-button icon="el-icon-search" class="search_btn" @click="searchBtn"></el-button></div>
      </div>
      <div class="operate_btn" v-if="!isSatis">
        <el-button type="primary" icon="el-icon-download" @click="downloadFile">导出</el-button>
      </div>
    </div>
    <div class="table_box" :class="isSatis ? 'statis_table' : ''">
      <el-table :data="tableData" border style="width: 100%" height="100%">
        <el-table-column prop="groupId" label="id" width="50" />
        <el-table-column prop="ranking" label="小组整体排名" />
        <el-table-column prop="groupName" label="组名" />
        <el-table-column prop="groupNum" label="小组人数" v-if="!isSatis" />
        <el-table-column prop="area" label="所在赛区" v-if="!isSatis" />
        <el-table-column prop="communityName" label="所在社区" v-if="!isSatis" />
        <el-table-column prop="punchAndAward" label="积分" />

        <el-table-column width="60" label="操作">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="本期总结" placement="top" v-if="!isSatis">
              <el-button type="primary" circle icon="el-icon-s-data" @click="curSummary(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="查看打卡详情" placement="top" v-if="isSatis">
              <el-button type="primary" circle icon="el-icon-document" @click="taskDetails(scope.row)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog :visible.sync="sumDialog" width="50%" :close-on-click-modal="false" :close-on-press-escape="false"
      class="set_dialog_style">
      <div slot="title" class="dialog_title">
        <span></span>本期总结
      </div>
      <current-sum :searchInfo="searchForm" :groupId="groupId" :key="timer"></current-sum>
    </el-dialog>
    <new-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size=pageBox.pageSize
      layout="total,sizes, prev, pager, next" :total=pageBox.total>
    </new-pagination>
  </div>
</template>

<script>
import api from '@/api/index'
// import { chinaArea } from '@/utils/china-area-data'
import { timeSlot, taskTypeList } from '@/utils/baseData'
import moment from 'moment'
import CurrentSum from "./components/current-sum";
export default {
  props: {
    isSatis: {
      type: Boolean,
      required: false
    }
  },
  name: 'index',
  components: { CurrentSum },
  data() {
    return {
      beginPickerOptions: {
        disabledDate: (time) => {
          if (this.endTime) {
            const endTime = new Date(this.endTime);
            return time.getTime() >= endTime.getTime()
          } else {
            return false
          }
        },
      },
      endPickerOptions: {
        disabledDate: (time) => {
          if (this.startTime) {
            const beginTime = new Date(this.startTime);
            return time.getTime() <= beginTime.getTime();
          } else {
            return false
          }
        },
      },
      timeSlot: timeSlot,
      araeList: [],
      communityList: [],
      taskTlist: taskTypeList,
      tableData: [],
      pageBox: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },

      searchForm: {
        periodType: 1,
        startTime: null,
        endTime: null,
        area: null,
        code: null,
        //tastTypes: [0, 1, 2, 3, 5, 6, 7, 8],
        communityId: null
      },
      endTime: '',
      startTime: '',
      sumDialog: false,
      groupId: null,
      timer: null
    }
  },
  created() {
    this.fetchUserPower()
  },
  mounted() {

  },
  methods: {
    //根据UserID回显权限res,拿到医生权限管理的社区
    async fetchUserPower() {
      try {
        let res = await api.getUserPower(this.$store.state.userId)
        if (res.code == 200) {
          if (this.$store.state.role !== 'admin') {
            this.searchForm.communityIds = res.data.comId
          }
          this.getGroupRanking(this.searchForm, this.pageBox)
        }
      } catch (e) {

      }
    },
    async getGroupRanking() {
      try {
        const res = await api.groupRanking(this.searchForm, this.pageBox)
        if (res.code == 200) {
          this.tableData = res.rows
          this.pageBox.total = res.total
        } else {
          this.$message.error(res.msg)
        }
      } catch (e) {
        this.$message.error(e.msg)
      }
    },
    /* 一页显示多少条切换 */
    handleSizeChange(val) {
      this.pageBox.pageSize = val
      this.getGroupRanking()
    },
    /* 分页切换 */
    handleCurrentChange(val) {
      this.pageBox.pageNum = val
      this.getGroupRanking()
    },
    getArea() {
      this.araeList = this.$store.state.userArea
    },
    // 根据地区查询社区

    async getAreaCode(val) {
      this.searchForm.communityId = null
      let data = {
        code: val,
      }
      let pageBox = {
        pageNum: 1,
        pageSize: 99999
      }
      let res = await api.getCommunitySystemUser(data, pageBox)
      if (res.code == 200 && res.rows) {
        this.communityList = res.rows
      } else {
        this.communityList = []
      }
    },
    // 点击查询按钮
    searchBtn() {
      const _this = this
      if (_this.searchForm.periodType == 4) {
        if (_this.startTime == '' || _this.startTime == null) {
          _this.$message.warning('请选择起始时间')
          return false
        } else if (_this.endTime == '' || _this.endTime == null) {
          _this.$message.warning('请选择截止时间')
          return false
        } else {
          _this.searchForm.startTime = moment(_this.startTime).format('YYYYMMDD')
          _this.searchForm.endTime = moment(_this.endTime).format('YYYYMMDD')
        }
      }
      _this.getGroupRanking()
    },
    // 导出
    async downloadFile() {
      try {
        let res = await api.exportGroupRank(this.searchForm)
        let name = '小组打卡排名'
        let blob = new Blob([res], { type: "xls" });
        let downloadElement = document.createElement("a");
        let href = window.URL.createObjectURL(blob); //创建下载的链接
        downloadElement.href = href;
        downloadElement.download = `${name}.xls`; //下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
        window.URL.revokeObjectURL(href); //释放掉blob对象
        this.$message.success(`导出成功`);
      } catch (e) {

      }
    },
    // 本期总结
    curSummary(row) {
      this.sumDialog = true
      this.timer = new Date().getTime()
      this.groupId = row.groupId
    },
    //查看打卡详情
    taskDetails(row) {
      this.$emit('showDetails', row)
    },
  },
  watch: {}
}
</script>

<style scoped lang="less">
.set_input_style {
  width: 180px;
}

.search_box {
  width: 65% !important;
  flex-wrap: wrap;
  height: 90px;
}

.operate_btn {
  width: 40% !important;
  text-align: right;
}

.table_box {
  height: calc(100% - 193px);
  margin: 10px;
}

.statis_table {
  height: calc(100% - 153px);
}

.picker_box {
  width: 180px !important;
}

/deep/.el-select__tags {
  flex-wrap: unset;
  overflow: auto;
}

.statis {
  margin: 0 !important;
  height: 100%;
  width: 100%;
}
</style>
